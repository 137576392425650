<template>
    <div class="news">
        <a-button type="primary" @click="$router.push({ name: 'news-edit' })"
            >新增</a-button
        >
        <a-list
            item-layout="horizontal"
            :data-source="dataList"
            :pagination="pagination"
        >
            <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta>
                    <div class="avatar" slot="avatar">
                        <img
                            v-show="item.coverImg"
                            :src="item.coverImg"
                            alt="文章封面"
                        />
                    </div>
                    <div class="title" slot="title">
                        <span>{{
                            item.createTime.substring(
                                0,
                                item.createTime.indexOf(" ")
                            )
                        }}</span>
                        <ul v-show="item.tags.length">
                            <li
                                v-for="(tag, tagIndex) in item.tags"
                                :key="'tag' + tagIndex"
                            >
                                <a-tag color="#2db7f5">
                                    {{ tag.tagName }}
                                </a-tag>
                            </li>
                        </ul>
                    </div>
                    <div class="description" slot="description">
                        <div class="description_content">
                            <span
                                @click="goPage(item)"
                                style="cursor: pointer"
                                >{{ item.title }}</span
                            >
                        </div>
                        <div class="description_viewCount">
                            阅读{{ item.viewTimes }}
                        </div>
                    </div>
                </a-list-item-meta>

                <a
                    slot="actions"
                    @click="
                        $router.push({
                            name: 'news-edit',
                            query: { id: item.id },
                        })
                    "
                >
                    修改
                </a>
                <a-popconfirm
                    slot="actions"
                    title="是否删除该新闻?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirm(item)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </a-list-item>
        </a-list>
    </div>
</template>

<script>
export default {
    name: "news",
    data() {
        return {
            dataList: [
                {
                    title: "标题",
                    createTime: "09:00",
                    content:
                        "测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容测试内容",
                    tags: [{ tagName: "tag1" }, { tagName: "tag2" }],
                    viewTimes: "666",
                },
            ],
            pagination: {
                onChange: (page) => {
                    this.pagination.current = page;
                    this.getList();
                },
                current: 1,
                pageSize: 4,
                total: 0,
            },
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        goPage(item) {
            window.open(
                `https://www.skiffenergy.com/news/detail?id=${item.id}`,
                "_blank"
            );
        },
        // 确认删除新闻
        confirm(item) {
            this.$axios
                .delete("/news/delete", {
                    params: { id: item.id },
                })
                .then(() => {
                    this.$message.success("删除成功");
                    this.pagination.current = 1;
                    this.getList();
                });
        },
        // 新闻列表
        getList() {
            this.$axios
                .get("/news/list", {
                    params: {
                        pageNum: this.pagination.current,
                        pageSize: this.pagination.pageSize,
                    },
                })
                .then((res) => {
                    this.dataList = res.list;
                    this.pagination.total = res.total;
                });
        },
    },
};
</script>

<style lang="scss">
.news {
    .ant-list-item-meta {
        align-items: initial;
        .ant-list-item-meta-content {
            display: flex;
            flex-direction: column;
            .ant-list-item-meta-description {
                flex: 1;
                .description {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    &_content {
                        width: 600px;
                        margin-top: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        // background-color: red;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .title {
        display: flex;
        ul {
            display: flex;
            align-items: center;
            li {
                list-style: none;
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
